module controllers {
    export module master {

        interface IPackagesCtrlScope extends ng.IScope {
        }

        interface IPackagesParams extends ng.ui.IStateParamsService {
        }

        export class packagesCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "cartonService",
                "$state",
                "$stateParams",
                "$transitions",
                "menuService"
            ];

            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            selectedRows: Array<interfaces.master.PackageDisplay> = [];
            searchAccordian: boolean = false;
            myHook: any;

            packageSearch: interfaces.master.IPackagesSearch = {
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string = "Packages";
            sortName: string;
            sortDirection: string;
            allowDeletePackages: boolean;

            packageDeleteList : interfaces.master.PackageDelete = { PackageDeleteList:[]};


            filterNames: string[] = ["EntityCode","Carton","CountryCode","PackageType","DispatchDate","Valid","ValidDate","TransactionNumber"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];
            
            constructor(private $scope: IPackagesCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private cartonService: interfaces.master.ICartonService,
                private $state: ng.ui.IStateService,
                private $stateParams: IPackagesParams,
                private $transitions: ng.ui.core.ITransition,
                private menuService: interfaces.applicationcore.IMenuService,
            ) {
                this.packageSearch = {
                };

                this.getPackagesDeleteRight();
                this.loadPackages();
                
                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.Packages',
                    from: 'auth.MasterData.Packages.**'
                }, () => {
                    this.loadPackages();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });

            }

            getPackagesDeleteRight() {
                this.allowDeletePackages = false;
                return this.menuService.getGTSConnectMenuItem(682).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.allowDeletePackages = true;
                    }
                }).$promise;
            }

            public viewPackageDetail(packageId: number){
                this.$state.go("auth.MasterData.Packages.PackageDetail", { packageId: packageId});
            }

            loadPackages(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'packages'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.packageSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.cartonService.getList().query(params, (result: Array<interfaces.master.PackageDisplay>) => {
                            this.gvwPackages.data = result;
                            this.packageDeleteList= { PackageDeleteList:[]};
                            if (result[0]) {
                                this.gvwPackages.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwPackages.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.gridApi) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            public gvwPackages: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [
                    {
                        name: "Detail",
                        displayName: " ",
                        field: "Detail",
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: `
                        <div class="GridButton">
                            <center>
                                <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.packagesCtrl.viewPackageDetail(row.entity.packageId)">
                                    <span class="fa fa-eye"></span>
                                </button>
                            </center>
                        </div>`,
                        width: 40,
                    },
                    {
                        name: "EntityCode",
                        displayName: "Entity",
                        field: "EntityCode",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "Carton",
                        displayName: "Carton",
                        field: "Carton",
                        width: 200,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "CountryCode",
                        displayName: "Country",
                        field: "CountryCode",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "PackageType",
                        displayName: "Type",
                        field: "PackageType",
                        width: 60,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },         
                    {
                        name: "Consignee",
                        displayName: "Consignee",
                        field: "Consignee",
                        width: 80,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                    {
                        name: "DispatchDate",
                        displayName: "Dispatch Date",
                        field: "DispatchDate",
                        enableCellEditOnFocus: false,
                        enableCellEdit: false,
                        width: 110,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="DateFrom-{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                            </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="DispatchDate"></gts-date>
                                </div>`,
                    },
                    {
                        name: 'Valid',
                        field: 'Valid',
                        displayName: 'Valid',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                        width: 60,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                       
                    },
                    {
                        name: "ValidDate",
                        displayName: "Valid Date",
                        field: "ValidDate",
                        enableCellEditOnFocus: false,
                        enableCellEdit: false,
                        width: 110,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="DateFrom-{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                            </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="ValidDate"></gts-date>
                                </div>`
                    },
                    {
                        name: "TransactionNumber",
                        displayName: "Transaction Number",
                        field: "TransactionNumber",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`
                    },
                ]
            };


            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.packagesFilterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.packagesSortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });


                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });

                this.gridApi.selection.on.rowSelectionChangedBatch(this.$scope,(selectedBatch:uiGrid.IGridRow[])=>{

                    for (let row of selectedBatch){
                        if (row.isSelected){
                            this.selectRow(row);
                        }
                        else{
                            this.unselectRow(row);
                        }
                    }
                });

                this.gridApi.selection.on.rowSelectionChanged(this.$scope, (row: uiGrid.IGridRow) => {
                    if (row.isSelected){
                        this.selectRow(row);
                    }
                    else{
                        this.unselectRow(row);
                    }
                });

            }

            selectRow(row: uiGrid.IGridRow){
                if (!this.packageDeleteList.PackageDeleteList.find(x=> x.packageId === row.entity.packageId)){
                    var deletePackage : interfaces.master.Package = { packageId : row.entity.packageId};
                    this.packageDeleteList.PackageDeleteList.push(deletePackage);
                }
            }

            unselectRow(row: uiGrid.IGridRow){
                this.packageDeleteList.PackageDeleteList = this.packageDeleteList.PackageDeleteList.filter(x=> {return !(x.packageId === row.entity.packageId )});
            }

            packagesSortChange(gridApi) {
                this.DoSearch();
            }
            
            packagesFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            ListPageChange(newPage, pageSize) {

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            public DeletePackagesClick(){
                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to permanently delete "+  this.packageDeleteList.PackageDeleteList.length +  " Package(s)?").then((result: boolean) => {
                    if (result) {
                       this.DeletePackage();
                    }
                });
            }

            public DeletePackage(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'packages'
                },
                    () => {
                        var paramsObject = {
                            packageDelete: this.packageDeleteList,
                        };
                        return this.cartonService.deletePackages().save((this.packageDeleteList), (data: interfaces.applicationcore.IMessageHandler) => {
                            if (data.HasErrorMessage){
                                this.generalService.displayMessageHandler(data);
                            }   
                            else{
                                this.generalService.displayMessageHandler(data);
                                this.loadPackages();
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'packages'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.packageSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.cartonService.getList().query(params, (result: Array<interfaces.master.PackageDisplay>) => {
                            var currentDirtyRows: Array<interfaces.master.PackageDisplay> = [];


                            this.gvwPackages.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwPackages.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwPackages.totalItems = 0;
                            }

                                this.$timeout(() => {
                                if (this.gridApi) {
                                   // this.getFilters();
                                  //  this.setFilters();
                                }
                            });
                            
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            

            showAll_click() {
                this.packageSearch = {
                };
                if (this.gridApi !== undefined) {
                    this.gridApi.grid.clearAllFilters(true, true, true).then(() => {
                        this.gridApi.grid.resetColumnSorting(null);
                    });
                }

                this.searchAccordian = false;
                this.DoSearch();
            }

            search_click() {
                this.searchAccordian = false;
                this.DoSearch();
            }



            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.packageSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.gridApi) {

                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.cartonService.getPackagesExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
   
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }  
            


        }

         angular.module("app").controller("packagesCtrl", controllers.master.packagesCtrl);    
    }
}